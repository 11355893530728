import React from 'react';
import { Image, Text, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';

import SEO from '../components/seo';
import { Layout } from '../layout';
import { Container } from '../components/container';
import { Header } from '../components/Header';

const LegalInfoPage = props => {
  const { t } = useTranslation();

  return (
    <Layout {...props}>
      <SEO title="LEGAL INFO" keywords={['weboxit', 'boxit']} />
      <Header
        title={t('legalInfo.title')}
        subtitle={t('legalInfo.subtitle')}
      />
      <Container>
        <Flex flexDirection="column" alignItems="center">
          <Text pt="25px" fontSize="35px" lineHeight="0.74" color="blues.peacock">
          { t('legalInfo.text1') }
          </Text>
          <Text
            pt="25px"
            px="120px"
            fontSize="20px"
            color="marine"
            lineHeight="1.5"
            textAlign="center"
          >
          { t('legalInfo.text2') }
          </Text>
          <Text
            pt="35px"
            fontSize="20px"
            color="marine"
            lineHeight="1.5"
            textAlign="center"
          >
          { t('legalInfo.text3') }
          </Text>
        </Flex>
      </Container>
    </Layout>
  );
};

export default LegalInfoPage;
